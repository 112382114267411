//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import blok from '~/mixins/blok'
export default {
  mixins: [blok],
  computed: {

  }
}
